import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'

function Home() {
  return (<>

    <Sidebar />
    <div className='db'>

      {/* Content wrapper */}
      <div className="content-wrapper">
        {/* Content */}
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row pt-5">
            <div className="col-lg-12 col-md-4 order-1">
              <h4 className="fw-bold py-3 mb-4">
                <span className="text-muted fw-light">HRM /</span> Dashboard
              </h4>

              <div className="row">
                <div className="col-lg-3 col-md-12 col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        <div className="avatar flex-shrink-0">
                          <img
                            src="../../assets/img/icons/unicons/cc-warning.png"
                            alt="Credit Card"
                            className="rounded"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="btn p-0"
                            type="button"
                            id="cardOpt6"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="bx bx-dots-vertical-rounded" />
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="cardOpt6"
                          >
                            <a className="dropdown-item" href="javascript:void(0);">
                              View More
                            </a>
                            <a className="dropdown-item" href="javascript:void(0);">
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                      <span className="fw-semibold d-block mb-1">TOTAL STAFF</span>
                      <h3 className="card-title text-nowrap mb-1">6</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        <div className="avatar flex-shrink-0">
                          <img
                            src="../../assets/img/icons/unicons/wallet-info.png"
                            alt="Credit Card"
                            className="rounded"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="btn p-0"
                            type="button"
                            id="cardOpt6"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="bx bx-dots-vertical-rounded" />
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="cardOpt6"
                          >
                            <a className="dropdown-item" href="javascript:void(0);">
                              View More
                            </a>
                            <a className="dropdown-item" href="javascript:void(0);">
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                      <span className="fw-semibold d-block mb-1">NEW STAFF IN MONTH</span>
                      <h3 className="card-title text-nowrap mb-1">5</h3>
                      {/* <small className="text-success fw-semibold">
              <i className="bx bx-up-arrow-alt" /> +28.42%
            </small> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        <div className="avatar flex-shrink-0">
                          <img
                            src="../../assets/img/icons/unicons/cc-primary.png"
                            alt="Credit Card"
                            className="rounded"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="btn p-0"
                            type="button"
                            id="cardOpt6"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="bx bx-dots-vertical-rounded" />
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="cardOpt6"
                          >
                            <a className="dropdown-item" href="javascript:void(0);">
                              View More
                            </a>
                            <a className="dropdown-item" href="javascript:void(0);">
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                      <span className="fw-semibold d-block mb-1">WORKING</span>
                      <h3 className="card-title text-nowrap mb-1">5</h3>

                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        <div className="avatar flex-shrink-0">
                          <img
                            src="../../assets/img/icons/unicons/paypal.png"
                            alt="Credit Card"
                            className="rounded"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="btn p-0"
                            type="button"
                            id="cardOpt6"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="bx bx-dots-vertical-rounded" />
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="cardOpt6"
                          >
                            <a className="dropdown-item" href="javascript:void(0);">
                              View More
                            </a>
                            <a className="dropdown-item" href="javascript:void(0);">
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                      <span className="fw-semibold d-block mb-1">EXPENSES</span>
                      <h3 className="card-title text-nowrap mb-1">0</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <h5 className="card-header">Expired contracts that need to be extended</h5>
                <hr class="m-0" />
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Contract Code</th>
                        <th>Contract ID</th>
                        <th>Staff</th>
                        <th>Departments</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Sign day</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      <tr>
                        <td>No entries found</td>
                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='pt-3'>
                <div className="card">
                  <h5 className="card-header">List of nearly expired contracts</h5>
                  <hr class="m-0" />
                  <div className="table-responsive text-nowrap">
                    <table className="table">
                      <thead>
                        <tr>
                        <th>Contract Code</th>
                        <th>Contract ID</th>
                        <th>Staff</th>
                        <th>Departments</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Sign day</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0">
                        <tr>
                          <td>No entries found</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='pt-3'>
                  <div className="card">
                    <h5 className="card-header">Birthday in this month</h5>
                    <hr class="m-0" />
                    <div className="table-responsive text-nowrap">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>HR Code</th>
                            <th>Full name</th>
                            <th>Email</th>
                            <th>Department</th>
                            <th>Birthday</th>
                            <th>Sex</th>
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                          <tr>
                            <td>No entries found</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* / Content */}
      </div>
      {/* Content wrapper */}


    </div>
    <Footer />
  </>
  )
}

export default Home
