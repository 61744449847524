import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"
import axios from "axios";

function Addjobrole() {
    const navigate = useNavigate("")
    const[jobrole, setJobrole] = useState

    const data= {
        jobrole: jobrole,
    }

    function addposition() {
        axios.post("https://dms.maverickconsole.in/api/addjobrole", data)
            .then(response => response.data).then(console.log(data));
    }


    function refreshPage() {
        window.location.reload();
      }


    return (
        <>
            <Sidebar  />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        <div className="row pt-5">
                            <div className="card mb-4">
                                <p className="card-header">JOB ROLE</p>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                Role
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                    onChange={(e)=>setJobrole(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="role"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        Save
                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Addjobrole

