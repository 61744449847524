
import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"

function Test1() {
  const localpermission = localStorage.getItem("permission")
  const permission = JSON.parse(localpermission)
  const test1permission = permission.filter((i)=>i.name==="test")[0]?.permissions
  const navigate = useNavigate("")
  const [test1Data, setTest1Data] = useState([])
  useEffect(() => { getTest1() }, [])
  const getTest1 = () => {
    axios.get("https://dms.maverickconsole.in/api/test1").then((res) => setTest1Data(res.data.data))
  }
  const delFn = (x) => {
    axios.delete("https://dms.maverickconsole.in/api/test1/" + x).then((res) => getTest1())
  }
  return (
    <>
      <Sidebar />
      <div className='db'>

        {/* Content wrapper */}
        <div className="content-wrapper pt-5">
          {/* Content */}
          <div className="container-xxl flex-grow-1 container-p-y pt-5">

            <div className="card">
              <div class="row mt-9 card-header">
                <div class="col-md-8">
                  <h4 class="fw-bold py-3 mb-4">Test1</h4>
                </div>
                {test1permission.includes("add") ? <div class="col-md-3 ">
                  <button onClick={() => { navigate('/addtest1'); }} type="submit" className="btn btn-primary">Add New Test</button>
                </div> : null}
              </div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>SNo.</th>
                      <th>TESTING</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {test1Data.map((i, n) => <tr>
                      <td>{n + 1}</td>
                      <td>{i.title}</td>
                      <td>{i.post}</td>
                      <td>
                        {test1permission.includes("delete") ? <> <button type="button" className="btn btn-icon btn-outline-danger" onClick={() => delFn(i._id)}>
                          <i className="bx bx-trash-alt" />
                        </button>&nbsp;&nbsp;&nbsp;:</> : null}
                        {test1permission.includes("edit") ? <button onClick={() => {
                          localStorage.setItem("editdata", JSON.stringify(i))
                          navigate('/edittest')
                        }} type="button" className="btn btn-icon btn-outline-primary">
                          <i className="bx bx-edit" />
                        </button> : null}
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* / Content */}
        </div>
        {/* Content wrapper */}


      </div>
    </>

  )
}

export default Test1
