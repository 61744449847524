
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"

function Editcity() {
    const navigate = useNavigate()
    const localData = localStorage.getItem("editcity")
    const parseData = JSON.parse(localData)

    const [country, setCountry] = useState(parseData.country)
    const [state, setState] = useState(parseData.state)
    const [city, setCity] = useState(parseData.city)

   
    function sumitEditCity() {
        const data = {
            country: country,
            state: state,
            city: city,
        }
        axios.put('https://dms.maverickconsole.in/api/city/'+parseData._id, data)
            .then(()=> navigate('/city'))
    }


    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        <div className="row pt-5">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Add City</h5>
                                </div>

                                <div className="card-body">
                                    <div className='row'>
                                    <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                   Country Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                    value={country}
                                                        onChange={(e) =>setCountry(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Country"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    State Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                    value={state}
                                                        onChange={(e) =>setState(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="state"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    City Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="city"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={()=>sumitEditCity()}  className="btn btn-primary">
                                        Save
                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Editcity

