import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"

function City() {
  const navigate = useNavigate("")
  const [addcity, setAddcity] = useState([])

  useEffect(() => { getcity() }, []
  )

  const getcity = () => {
    axios.get("https://dms.maverickconsole.in/api/city")
      .then((res) => setAddcity(res.data.data))
  }
  const delectcity = (d) => {
    axios.delete("https://dms.maverickconsole.in/api/city/" + d)
      .then(() => getcity())
  }

  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <Sidebar />
      <div className='db'>

        {/* Content wrapper */}
        <div className="content-wrapper pt-5">
          {/* Content */}
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">Master /</span> City
            </h4>

            <div className="card">
              <div class="row mt-9 card-header">
                <div class="col-md-8">
                  <h4 class="fw-bold py-3 mb-4">City</h4>
                </div>
                <div class="col-md-3 ">
                  <button onClick={() => { navigate('/addcity'); refreshPage() }} type="submit" className="btn btn-primary">Add City</button>
                </div>
              </div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>

                    <tr>
                      <th>S NO.</th>
                      <th>COUNTRY NAME</th>
                      <th>STATE NAME</th>
                      <th>CITY NAME</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {addcity.map((i, n) => <tr>
                      <td>{n + 1}</td>
                      <td>{i.country}</td>
                      <td>{i.state}</td>
                      <td>{i.city}</td>
                      <td>
                        <button type="button" className="btn btn-icon btn-outline-danger" onClick={() => delectcity(i._id)}>
                          <i className="bx bx-trash-alt" />
                        </button>&nbsp;&nbsp;&nbsp;
                        <button onClick={() => {
                          localStorage.setItem(("editcity"), JSON.stringify(i))
                          navigate('/editcity')
                        }} type="button" className="btn btn-icon btn-outline-primary">
                          <i className="bx bx-edit" />
                        </button>
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* / Content */}
        </div>
        {/* Content wrapper */}


      </div>
    </>

  )
}

export default City
