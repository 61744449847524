import axios from "axios"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Sidebar from "../Sidebar"

function CategoryMaster() {
    const navigate = useNavigate()
    const [account_category, setAccount_category] = useState([])
    const [showcategory, setshowcategory] = useState([])

    const data = {
        account_category: account_category,
    }

    function submitCategory() {
        axios.post('https://dms.maverickconsole.in/api/accountcategory/addaccountcategory', data)
            .then(res => res.data).then(console.log(data))
    }

    useEffect(() => { getaccount_category() }, [])

    const getaccount_category = () => {
        axios.get("https://dms.maverickconsole.in/api/accountcategory")
            .then((res) => setshowcategory(res.data.data));
    }
    const deleteaccount_category = (d) => {
        axios.delete("https://dms.maverickconsole.in/api/accountcategory/" + d)
            .then(() => getaccount_category());
    }


    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        <div className="row pt-5">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Account Category</h5>
                                </div>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Category
                                                </label>

                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={account_category}
                                                        onChange={(e) => setAccount_category(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Category"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={submitCategory} type="submit" className="btn btn-primary">
                                        Save
                                    </button>

                                </div>
                            </div>
                            <div className="card">
                                <div className="table-responsive text-nowrap">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S NO.</th>
                                                <th>Account Category</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                            {showcategory.map((i, n) =>
                                                <tr>
                                                    <td>{n + 1}</td>
                                                    <td>{i.account_category}</td>
                                                    <td>
                                                        <button type="button" onClick={()=>deleteaccount_category(i._id)} className="btn btn-icon btn-outline-danger" >
                                                            <i className="bx bx-trash-alt" />
                                                        </button>&nbsp;&nbsp;&nbsp;
                                                        <button onClick={() => {
                                                            localStorage.setItem("CategoryData", JSON.stringify(i))
                                                            navigate('/editcategorymaster')

                                                        }} type="button" className="btn btn-icon btn-outline-primary">
                                                            <i className="bx bx-edit" />
                                                        </button>
                                                    </td>
                                                </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default CategoryMaster

