import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"

function Addmember() {
    const navigate = useNavigate("")
    function refreshPage() {
        window.location.reload();
      }

    return (
        <>
            <Sidebar  />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y">
                        {/* <h4 className="fw-bold py-3 mb-4">
                            <span className="text-muted fw-light" />
                        </h4> */}
                        <div className="row pt-5">
                            <ul className="nav nav-pills flex-column flex-md-row pt-3">
                                <li className="nav-item">
                                    <a onClick={() => { navigate('/profile'); refreshPage() }} class="btn btn-primary mb-2 nav-link"><i className="bx bx-user me-1" /> Profile</a>
                                </li>&nbsp;&nbsp;&nbsp;
                                <li className="nav-item">
                                    <a onClick={() => { navigate('/permission'); refreshPage() }} class="btn btn-primary mb-2 nav-link"><i className="bx bx-bell me-1" />  Permission</a>
                                </li>
                            </ul>

                            <div className="card mb-4">
                                <p className="card-header">Enable Email Two Factor Authentocation <br />Not Staff Member</p>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="HR">
                                                    HR Code
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="HR"
                                                        placeholder="Code"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="fullname">
                                                    Full Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="fullname"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="Sex" className="form-label">
                                                    Sex
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="Sex"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="">Select</option>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Birthday
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        id="BIRTHDAY"
                                                        placeholder="Date"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Birthplace">
                                                    Birthplace
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="Birthplace"
                                                        className="form-control"
                                                        placeholder="Place"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="town">
                                                    Home town
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="town"
                                                        className="form-control"
                                                        placeholder="home town"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="Material" className="form-label">
                                                    Material Status
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="Material"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="">Select</option>
                                                    <option>1</option>
                                                    <option>1</option>
                                                    <option>1</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Nation">
                                                    Nation
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="Nation"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Religion">
                                                    Religion
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="Religion"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Identification">
                                                    Identification
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Identification"
                                                        placeholder="name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="fullname">
                                                    Day for identity
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="date"
                                                        id="fullname"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="issue">
                                                    place of issue
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="issue"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Resident">
                                                    Resident
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Resident"
                                                        placeholder="name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Address">
                                                    Current Address
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="Address"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="literacy">
                                                    literacy
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="literacy"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="statu" className="form-label">
                                                    status
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="statu"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="">Select</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="position" className="form-label">
                                                    job position
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="position"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="">Select</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="workplace" className="form-label">
                                                    workplace
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="workplace"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="">Select</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="bank">
                                                    bank account#
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="bank"
                                                        placeholder="number"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="account">
                                                    Name of account
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="account"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="bankissue">
                                                    bank of issue
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="bankissue"
                                                        className="form-control"
                                                        placeholder="Issu"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="tax">
                                                    personal tax code
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="tax"
                                                        placeholder="Code"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="hourly">
                                                    hourly rate
                                                </label>
                                                <div className="input-group">
                                                    <input type="number" className="form-control" placeholder='rate' id='hourly' aria-label="Dollar amount (with dot and two decimal places)" />
                                                    <span className="input-group-text">₹</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="phone">
                                                    phone
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="phone"
                                                        placeholder="number"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="facebook">
                                                    facebook
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="facebook"
                                                        placeholder="facebook"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="linkedin">
                                                    linkedin
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="linkedin"
                                                        className="form-control"
                                                        placeholder="linkedin"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="skype">
                                                    skype
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="skype"
                                                        className="form-control"
                                                        placeholder="skype"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="emails">
                                                    email
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="emails"
                                                        placeholder="email"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="language" className="form-label">
                                                    default language
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="language"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="">System Default</option>
                                                    <option>Hindi</option>
                                                    <option>English</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="Sex" className="form-label">
                                                    direction
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="Sex"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="">System Default</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="emails">
                                                    email signature
                                                </label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="emails">
                                                    other info
                                                </label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="twilio">
                                                    Member Departments
                                                </label>
                                                <div className="form-check mt-3">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                                        {" "}
                                                        Business Department{" "}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="defaultCheck2"
                                                        defaultChecked=""
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck2">
                                                        {" "}
                                                        Sales{" "}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="defaultCheck3"
                                                        defaultChecked=""
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck3">
                                                        {" "}
                                                        Marketing{" "}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="disabledCheck1"
                                                        disabled=""
                                                    />
                                                    <label className="form-check-label" htmlFor="disabledCheck1">
                                                        {" "}
                                                        Development{" "}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="disabledCheck2"
                                                        disabled=""
                                                        defaultChecked=""
                                                    />
                                                    <label className="form-check-label" htmlFor="disabledCheck2">
                                                        {" "}
                                                        HR{" "}
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="twilio">
                                                    twilio phone number
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="twilio"
                                                        placeholder="name"
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="mb-3">
                                                <div className="form-check mt-3">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                                        {" "}
                                                        Administrator{" "}
                                                    </label>
                                                </div>
                                                <div className="form-check mt-3">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                                        {" "}
                                                        Send welcome email {" "}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="twilio">
                                                    Password
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        aria-label="Dollar amount (with dot and two decimal places)"
                                                    />
                                                    <span className="input-group-text"><i class="bx bx-hide"></i></span>
                                                    <span className="input-group-text"><i class="bx bx-refresh"></i></span>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        Save
                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Addmember

