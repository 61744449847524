import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Sidebar from "../Sidebar"

function EditcategoryMaster() {
    const navigate = useNavigate()
    const localdata = localStorage.getItem("CategoryData")
    const parseData = JSON.parse(localdata)
    const [account_category, setAccount_category] = useState(parseData.account_category)

   

    function EditCategory() {
        const data = {
            account_category: account_category,
        }

        axios.put('https://dms.maverickconsole.in/api/accountcategory/' + parseData._id, data)
        .then(()=> navigate('/accountcategory'))
    }



    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        <div className="row pt-5">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Account Category</h5>
                                </div>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Category
                                                </label>

                                                <div className="input-group input-group-merge">
                                                    <input
                                                    value={account_category}
                                                    onChange={(e)=>setAccount_category(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                    
                                                      
                                                    />
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={()=>EditCategory()} type="submit" className="btn btn-primary">
                                        Save
                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default EditcategoryMaster

