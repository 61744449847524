import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"

function Staff() {
  const navigate = useNavigate("")
  const [addstaff, setAddstaff] = useState([])

  useEffect(() => { getStaff() }, [])

  const getStaff = () => {
    axios.get("https://dms.maverickconsole.in/api/user")
      .then((res) => setAddstaff(res.data.data));
  }

  const deleteStaff = (d) => {
    axios.delete("https://dms.maverickconsole.in/api/user/"+d)
      .then(() => getStaff());
  }




  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <Sidebar />
      <div className='db'>
        {/* Content wrapper */}
        <div className="content-wrapper">
          {/* Content */}
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">HRM /</span> Staff
            </h4>
            <div className="card">
              <div class="row mt-9 card-header">
                <div class="col-md-8">
                  <h4 class="fw-bold py-3 mb-4">Staff</h4>
                </div>
                <div class="col-md-3 ">
                  <button onClick={() => { navigate('/profile'); refreshPage() }} type="submit" className="btn btn-primary">New Staff Member</button>
                </div>
              </div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>HR Code</th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Birthday</th>
                      <th>Sex</th>
                      <th>Role</th>
                      <th>Last Login</th>
                      <th>Active</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {addstaff.map((i, n)=>
                    <tr>
                      <td>{n+1}</td>
                      <td>{i.hrcode}</td>
                      <td>{i.fullname}</td>
                      <td>{i.email}</td>
                      <td>{i.birthday}</td>
                      <td>{i.sex}</td>
                      <td>{i.role}</td>
                      <td>{i.last}</td>
                      
                      <td>  <div className="form-check form-switch">
                        <input className="form-check-input float-end" type="checkbox" role="switch" defaultChecked />
                      </div></td>
                      <td>{i.status}</td>
                      <td>
                        <button type="button" className="btn btn-icon btn-outline-danger" onClick={()=>deleteStaff(i._id)}>
                          <i className="bx bx-trash-alt" />
                        </button>&nbsp;&nbsp;&nbsp;
                        <button onClick={() => { navigate('/addjobposition'); refreshPage() }} type="button" className="btn btn-icon btn-outline-primary">
                          <i className="bx bx-edit" />
                        </button>
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* / Content */}
        </div>
        {/* Content wrapper */}


      </div>
    </>

  )
}

export default Staff
