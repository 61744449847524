import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('123');
  const navigate = useNavigate("")


  const Auth = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post('https://dms.maverickconsole.in/api/login/login', {
          email: email,
          password: password,
        })
        .then((res) => {
          if (res.data.msg === 'success') {
            localStorage.setItem("token", "token")
            localStorage.setItem("role", res.data.data.role)
            const abc = res.data.data.role
            axios.get("https://dms.maverickconsole.in/api/roles").then((res1)=>{
              const userrole = res1.data.data.filter((i)=>i.role===abc)[0].permission
              localStorage.setItem("permission", JSON.stringify(userrole))
              console.log(userrole)
            
            })
            navigate('/dashboard');
          } else setMsg(res.data.msg);
        });
    } catch (err) {
      if (err.res) {
        setMsg(err.res.data.msg);
      }
    }
  };



  function refreshPage() {
    window.location.reload();
  }
  return (
    <>
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100 pt-5">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="img-fluid"
              alt="Sample image"
            />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <div className="d-flex flex-row align-items-center justify-content-center">
             
              <h2 class="form-title">Sign In</h2><br/>
             
            </div>
            <div className="alert alert-danger" style={{ visibility: msg === "123" ? "hidden" : "" }} role="alert">
                {msg}
              </div>
            {/* Email input */}
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="form3Example3">
                Email address
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control form-control-lg"
                placeholder="Enter a valid email address"
              />
            </div>
            {/* Password input */}
            <div className="form-outline mb-3">
              <label className="form-label" htmlFor="form3Example4">
                Password
              </label>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="form-control form-control-lg"
                placeholder="Enter password"
              />

            </div>
            <div className="d-flex justify-content-between align-items-center">
              {/* Checkbox */}
              <div className="form-check mb-0">
                <input

                  className="form-check-input me-2"
                  type="checkbox"
                  defaultValue=""
                />
                <label className="form-check-label" htmlFor="form2Example3">
                  Remember me
                </label>
              </div>
              <a onClick={() => { navigate('/'); refreshPage() }} className="text-body" style={{ cursor: "pointer" }}>
                Forgot password?
              </a>
            </div>
            <div className="text-center text-lg-start mt-4 pt-2">
              <button onClick={Auth}
                type="button"
                className="btn btn-primary btn-lg"
                style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem", cursor: "pointer" }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export default Login
