import './App.css';
import { HashRouter, Routes, Route } from "react-router-dom";
import Home from './component/Home';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Staff from './component/page/Staff';
import Addmember from './component/page/Addmember';
import Permission from './component/page/Permission';
import Profile from './component/page/Profile';
import Login from './component/page/Login';
import Signup from './component/page/Signup';
import Jobposition from './component/page/Jobposition';
import Addjobposition from './component/page/Addjobposition';
import EditRole from './component/page/EditRole';
import Jobrole from './component/page/Jobrole';
import Addjobrole from './component/page/Addjobrole';
import Role from './component/page/Role';
import User from './component/page/User';
import Test1 from './component/page/Test1';
import Edittest from './component/page/Edittest';
import Test from './component/page/Test';
import Addtest1 from './component/page/Addtest1';
import Addtest from './component/page/Addtest';
import Country from './component/page/Country';
import Addcountry from './component/page/Addcountry';
import State from './component/page/State';
import Addstate from './component/page/Addstate';
import City from './component/page/City';
import Addcity from './component/page/Addcity';
import Category from './component/page/Category';
import Addcategory from './component/page/Addcategory';
import Subcategory from './component/page/Subcategory';
import Addsubcategory from './component/page/Addsubcategory';
import Expenses from './component/page/Expenses';
import Addexpenses from './component/page/Addexpenses';
import Editcountry from './component/page/Editcountry';
import Editstate from './component/page/Editstate';
import Editcity from './component/page/Editcity';
import CategoryMaster from './component/page/CategoryMaster';
import Editcategory from './component/page/Editcategory';
import Editsubcategory from './component/page/Editsubcategory';
import EditcategoryMaster from './component/page/EditcategoryMaster';
import Editexpenses from './component/page/Editexpenses';

function App() {

  return (
    <div>
      <HashRouter>
        <Routes>
          <Route exact path='/' element={< Login />}></Route>
          <Route exact path='/signup' element={< Signup />}></Route>
          <Route exact path='/dashboard' element={< Home />}></Route>
          <Route exact path='/staff' element={< Staff />}></Route>
          <Route exact path='/addmember' element={< Addmember />}></Route>
          <Route exact path='/permission' element={< Permission />}></Route>
          <Route exact path='/profile' element={< Profile />}></Route>
          <Route exact path='/jobposition' element={< Jobposition />}></Route>
          <Route exact path='/addjobposition' element={< Addjobposition />}></Route>
          <Route exact path='/EditRole' element={< EditRole/>}></Route>
          <Route exact path='/department' element={< Jobrole />}></Route>
          <Route exact path='/addjobrole' element={< Addjobrole />}></Route>
          <Route exact path='/role' element={< Role />}></Route>
          <Route exact path='/user' element={< User />}></Route>
          <Route exact path='/test1' element={< Test1 />}></Route>
          <Route exact path='/test' element={< Test />}></Route>
          <Route exact path='/addtest1' element={< Addtest1 />}></Route>
          <Route exact path='/addtest' element={< Addtest />}></Route>
          <Route exact path='/edittest' element={< Edittest />}></Route>
          <Route exact path='/country' element={< Country />}></Route>
          <Route exact path='/addcountry' element={< Addcountry />}></Route>
          <Route exact path='/state' element={< State />}></Route>
          <Route exact path='/addstate' element={< Addstate />}></Route>
          <Route exact path='/city' element={< City />}></Route>
          <Route exact path='/addcity' element={< Addcity />}></Route>
          <Route exact path='/category' element={< Category />}></Route>
          <Route exact path='/addcategory' element={< Addcategory />}></Route>
          <Route exact path='/subcategory' element={< Subcategory />}></Route>
          <Route exact path='/addsubcategory' element={< Addsubcategory />}></Route>
          <Route exact path='/expenses' element={< Expenses />}></Route>
          <Route exact path='/addexpenses' element={< Addexpenses />}></Route>
          <Route exact path='/editcountry' element={< Editcountry />}></Route>
          <Route exact path='/editstate' element={< Editstate />}></Route>
          <Route exact path='/editcity' element={< Editcity />}></Route>
          <Route exact path='/accountcategory' element={< CategoryMaster />}></Route>
          <Route exact path='/editcategory' element={< Editcategory />}></Route>
          <Route exact path='/editsubcategory' element={< Editsubcategory />}></Route>
          <Route exact path='/editcategorymaster' element={< EditcategoryMaster />}></Route>
          <Route exact path='/editexpenses' element={< Editexpenses />}></Route>
        </Routes>
        <HelmetProvider>
          <Helmet>
            <script src="assets/vendor/libs/jquery/jquery.js"></script>
            <script src="assets/vendor/libs/popper/popper.js"></script>
            <script src="assets/vendor/js/bootstrap.js"></script>
            <script src="assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
            <script src="assets/vendor/libs/hammer/hammer.js"></script>
            <script src="assets/vendor/libs/i18n/i18n.js"></script>
            <script src="assets/vendor/libs/typeahead-js/typeahead.js"></script>
            <script src="assets/vendor/js/menu.js"></script>
            <script src="assets/vendor/libs/cleavejs/cleave.js"></script>
            <script src="assets/vendor/libs/cleavejs/cleave-phone.js"></script>
            <script src="assets/vendor/libs/moment/moment.js"></script>
            <script src="assets/vendor/libs/flatpickr/flatpickr.js"></script>
            <script src="assets/vendor/libs/select2/select2.js"></script>
            <script src="assets/js/main.js"></script>
            <script src="assets/js/form-layouts.js"></script>
          </Helmet>
        </HelmetProvider>
      </HashRouter>
    </div>
  );
}

export default App;
