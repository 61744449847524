import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"

function Expenses() {
  const navigate = useNavigate("")
  const [addexpense, setAddexpense] = useState([])

  useEffect(() => { getExpense() }, [])


  const getExpense = () => {
    axios.get("https://dms.maverickconsole.in/api/expense")
      .then((res) => setAddexpense(res.data.data))
  }

  const deleteExpense = (d) =>{
  axios.delete("https://dms.maverickconsole.in/api/expense/"+d)
      .then(() => getExpense())
  }
  


  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <Sidebar />
      <div className='db'>

        {/* Content wrapper */}
        <div className="content-wrapper">
          {/* Content */}
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">HRM /</span> Expenses
            </h4>
            {/* http://192.168.1.4:5001/getcontact */}
            {/* http://192.168.1.4:5001/api/contact */}
            {/* Basic Bootstrap Table */}

            <div className="card">
              <div class="row mt-9 card-header">
                <div class="col-md-8">
                  <h4 class="fw-bold py-3 mb-4">Expenses</h4>
                </div>
                <div class="col-md-3 ">
                  <button onClick={() => { navigate('/addexpenses'); refreshPage() }} type="submit" className="btn btn-primary">Add Expenses</button>
                </div>
              </div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S No.</th>
                      <th>Category</th>
                      <th>Amount</th>
                      <th>Name</th>
                      <th>Receipt</th>
                      <th>Date</th>
                      <th>Project</th>
                      <th>Customer</th>
                      <th>Invoice</th>
                      <th>Reference</th>
                      <th>Payment Mode</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {addexpense.map((i, n)=>
                    <tr>
                      <td>{n+1}</td>
                      <td>{i.category}</td>
                      <td>{i.amount}</td>
                      <td>{i.name}</td>
                      <td>{i.receipt}</td>
                      <td>{i.date}</td>
                      <td>{i.project}</td>
                      <td>{i.customer}</td>
                      <td>{i.invoice}</td>
                      <td>{i.reference}</td>
                      <td>{i.payment}</td>
                      <td> <td>
                          <button type="button" className="btn btn-icon btn-outline-danger" onClick={()=>deleteExpense(i._id)}>
                            <i className="bx bx-trash-alt" />
                          </button>&nbsp;&nbsp;&nbsp;
                          <button onClick={()=>{localStorage.setItem(("expenseData"),JSON.stringify(i)) 
                          navigate('/editexpenses')
                          }} type="button" className="btn btn-icon btn-outline-primary">
                            <i className="bx bx-edit" />
                          </button>
                        </td></td>
                      <td>
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* / Content */}
        </div>
        {/* Content wrapper */}


      </div>
    </>

  )
}

export default Expenses
