
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"

function Addcity() {
    const navigate = useNavigate()
    const [country, setCountry] = useState("")
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [addcountry, setAddcountry] = useState([])
    const [addstate, setAddstate] = useState([])

    const data = {
        country: country,
        state: state,
        city: city,
    }

    function submitCity() {
        axios.post('https://dms.maverickconsole.in/api/city/addCity', data)
            .then((res) => navigate('/city'))
    }

    useEffect(() => { getcountry() }, [])

    const getcountry = () => {
      axios.get("https://dms.maverickconsole.in/api/country")
        .then((res) => setAddcountry(res.data.data));
    }

    useEffect(() => { getState()}, [] )

  const getState = () => {
    axios.get("https://dms.maverickconsole.in/api/state")
      .then((res) => setAddstate(res.data.data))
  }


    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        <div className="row pt-5">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Add City</h5>
                                </div>

                                <div className="card-body">
                                <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label htmlFor="position" className="form-label">
                                                Country Name
                                                </label>
                                                <select
                                                    value={country}
                                                    onChange={(e) => setCountry(e.target.value)}
                                                    className="form-select"
                                                    aria-label="Default select example"

                                                >
                                                    <option selected="" disabled>Select</option>
                                                    {addcountry.map((i) => <option>{i.country}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label htmlFor="position" className="form-label">
                                                State Name
                                                </label>
                                                <select
                                                    value={state}
                                                    onChange={(e) => setState(e.target.value)}
                                                    className="form-select"
                                                    aria-label="Default select example"

                                                >
                                                    <option selected="" disabled>Select</option>
                                                    {addstate.map((i) => <option>{i.state}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    City Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="city"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={submitCity} type="submit" className="btn btn-primary">
                                        Save
                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Addcity

