
import { useState } from 'react';
import Sidebar from "../Sidebar"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Editstate() {
   
    const localdata = localStorage.getItem("stateData")
    const parseData = JSON.parse(localdata)
    const navigate = useNavigate()
    const[state, setState] = useState(parseData.state)
    const[country, setCountry] = useState(parseData.country)

   

    function submitState() {
        const data= {
            state: state,
            country: country,
        }
        axios.put("https://dms.maverickconsole.in/api/state/" + parseData._id, data)
        .then(()=> navigate('/state'))
    }
    

    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        <div className="row pt-5">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Add State</h5>
                                </div>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                   Country Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                    value={country}
                                                        onChange={(e) =>setCountry(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Country"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    State Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                    value={state}
                                                        onChange={(e) =>setState(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="state"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={()=>submitState()} type="submit" className="btn btn-primary">
                                        Save
                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Editstate