
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"
import axios from 'axios'

function Test() {
  const localpermission = localStorage.getItem("permission")
  const permission = JSON.parse(localpermission)
  const test1permission = permission.filter((i)=>i.name==="test2")[0]?.permissions
  const[testdata, setTestData] = useState([])
  useEffect(() => { getTest() }, [])
  const getTest = () => {
    axios.get("https://dms.maverickconsole.in/api/test1").then((res) => setTestData(res.data.data))
  }
  const delFn = (x) => {
    axios.delete("https://dms.maverickconsole.in/api/test1/" + x).then((res) => getTest())
  }
  const navigate = useNavigate("")
  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <Sidebar />
      <div className='db'>

        {/* Content wrapper */}
        <div className="content-wrapper pt-5">
          {/* Content */}
          <div className="container-xxl flex-grow-1 container-p-y pt-5">

            <div className="card">
              <div class="row mt-9 card-header">
                <div class="col-md-8">
                  <h4 class="fw-bold py-3 mb-4">Test</h4>
                </div>
                <div class="col-md-3 ">
                  <button onClick={() => { navigate('/addtest'); refreshPage() }} type="submit" className="btn btn-primary">Add New Test</button>
                </div>
              </div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>SNo.</th>
                      <th>ROLE</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {testdata.map((i, n)=><tr>
                      <td>{n+1}</td>
                      <td>{i.title}</td>
                      <td>{i.post}</td>
                      <td>
                      {test1permission.includes("delete") ? <> <button type="button" className="btn btn-icon btn-outline-danger" onClick={() => delFn(i._id)}>
                          <i className="bx bx-trash-alt" />
                        </button>&nbsp;&nbsp;&nbsp;:</> : null}
                        {test1permission.includes("edit") ? <button onClick={() => {
                          localStorage.setItem("editdata", JSON.stringify(i))
                          navigate('/edittest')
                        }} type="button" className="btn btn-icon btn-outline-primary">
                          <i className="bx bx-edit" />
                        </button> : null}
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* / Content */}
        </div>
        {/* Content wrapper */}


      </div>
    </>

  )
}

export default Test
