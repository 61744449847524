import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"

function Jobrole() {
  const navigate = useNavigate("")
  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <Sidebar />
      <div className='db'>

        {/* Content wrapper */}
        <div className="content-wrapper">
          {/* Content */}
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">HRM /</span> Staff
            </h4>
            {/* http://192.168.1.4:5001/getcontact */}
            {/* http://192.168.1.4:5001/api/contact */}
            {/* Basic Bootstrap Table */}

            <div className="card">
              <div class="row mt-9 card-header">
                <div class="col-md-8">
                  <h4 class="fw-bold py-3 mb-4">Department</h4>
                </div>
                <div class="col-md-3 ">
                  <button onClick={() => { navigate('/addjobposition'); refreshPage() }} type="submit" className="btn btn-primary">Add New Job Role</button>
                </div>
              </div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>SNo.</th>
                      <th>Department Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    <tr>
                      <td>1</td>
                      <td>HR</td>
                      <td>
                        <button type="button" className="btn btn-icon btn-outline-danger">
                          <i className="bx bx-trash-alt" />
                        </button>&nbsp;&nbsp;&nbsp;
                        <button onClick={() => { navigate('/addjobposition'); refreshPage() }} type="button" className="btn btn-icon btn-outline-primary">
                          <i className="bx bx-edit" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* / Content */}
        </div>
        {/* Content wrapper */}


      </div>
    </>

  )
}

export default Jobrole
