import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"
import axios from "axios";
import { useEffect, useState } from 'react';

function Permission() {
  useEffect(()=>{modelData()},[])
  const[role, setRole] = useState('')
  const[bulkpdf, setBulkpdf] = useState('')
  const[contractown, setContractown] = useState('')
  const[contractglobal, setContractglobal] = useState('')
  const navigate = useNavigate("")


  function permissiondata() {
    axios.post("https://dms.maverickconsole.in/api/addEmployee", )
        .then(response => response.data).then(console.log());
}
function modelData() {
  axios.get("https://dms.maverickconsole.in/api/user/allmodel")
      .then(response => setModels(response.data.data))
}

  function refreshPage() {
    window.location.reload();
  }
const [models,setModels]=useState([])
  return (
    <>
      <Sidebar />
      <div className='db'>

        {/* Content wrapper */}
        <div className="content-wrapper">
          {/* Content */}
          <div className="container-xxl flex-grow-1 container-p-y">
            {/* <h4 className="fw-bold py-3 mb-4">
                            <span className="text-muted fw-light" />
                        </h4> */}
            <div className="row pt-5">
              <ul className="nav nav-pills flex-column flex-md-row pt-3">
                <li className="nav-item">
                  <a onClick={() => {navigate('/profile');refreshPage()}} class="btn btn-primary mb-2 nav-link"><i className="bx bx-user me-1" /> Profile</a>
                </li>&nbsp;&nbsp;&nbsp;
                <li className="nav-item">
                  <a onClick={() => {navigate('/permission');refreshPage()}} class="btn btn-primary mb-2 nav-link"><i className="bx bx-bell me-1" />  Permission</a>
                </li>
              </ul>

              <div className="card mb-4">
                <div className="card-body">
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className="mb-3">
                        <label htmlFor="statu" className="form-label">
                          Role
                        </label>
                        <select
                        value={role}
                        onChange={(e)=>setRole(e.target.value)}
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected="">Select</option>
                          <option>Admin</option>
                          <option>User</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <p className="form-label" style={{fontSize:"18px",color:"black"}}>Permission</p>
                  <div className="card">
                    <div className="table-responsive text-nowrap">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col"><b style={{fontSize:"15px"}}>Feauture</b></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"><b style={{fontSize:"15px"}}>Capabilities</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Bulk PDF Export</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <input
                              value={bulkpdf}
                              onChange={(e)=>setBulkpdf(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="P">
                                {" "}
                                View(Global){" "}
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Contract</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <input
                              value={contractown}
                              onChange={(e)=>setContractown(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="v">
                                {" "}
                                View(own){" "}
                              </label>
                              <br />
                              <input
                              value={contractglobal}
                              onChange={(e)=>setContractglobal(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="G">
                                {" "}
                                View(Global){" "}
                              </label>
                              <br />
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Create1">
                                {" "}
                                Create{" "}
                              </label>
                              <br />
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="edit">
                                {" "}
                                Edit{" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Delete">
                                {" "}
                                Delete{" "}
                              </label>

                            </td>
                          </tr>
                          <tr>
                            <th scope="row">HRM</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="v1">
                                {" "}
                                View(own){" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="G1">
                                {" "}
                                View(Global){" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Create2">
                                {" "}
                                Create{" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="edit1">
                                {" "}
                                Edit{" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Delete1">
                                {" "}
                                Delete{" "}
                              </label>

                            </td>
                          </tr>
                          <tr>
                            <th scope="row">HR Dashboard</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <input
                            value={""}
                            onChange={(e)=>(e.target.value)}
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Gq">
                                {" "}
                                View(Global){" "}
                              </label></td>
                          </tr>
                          <tr>
                            <th scope="row">HR Receive Staff</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Gn">
                                {" "}
                                View(Global){" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Create4">
                                {" "}
                                Create{" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="edit3">
                                {" "}
                                Edit{" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Delete3">
                                {" "}
                                Delete{" "}
                              </label></td>
                          </tr>
                          <tr>
                            <th scope="row">HR Records</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Gm">
                                {" "}
                                View(Global){" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Create3">
                                {" "}
                                Create{" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="edit2">
                                {" "}
                                Edit{" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Delete2">
                                {" "}
                                Delete{" "}
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Staff Roles</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Gb">
                                {" "}
                                View(Global){" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;  
                              <label className="form-check-label" htmlFor="Create4">
                                {" "}
                                Create{" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="edit3">
                                {" "}
                                Edit{" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Delete3">
                                {" "}
                                Delete{" "}
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Setting</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Gc">
                                {" "}
                                View(Global){" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="editt">
                                {" "}
                                Edit{" "}
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Staff</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Gba">
                                {" "}
                                View(Global){" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Gbc">
                                {" "}
                                View(Own){" "}
                              </label>
                              <br />
                              <input
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="Createt">
                                {" "}
                                Create{" "}
                              </label>
                              <br />
                              <input
                              value={""}
                              onChange={(e)=>(e.target.value)}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                              />&nbsp;&nbsp;&nbsp;
                              <label className="form-check-label" htmlFor="edito">
                                {" "}
                                Edit{" "}
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='pt-3'>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>

                </div>
              </div>


            </div>
          </div>
          {/* / Content */}
        </div>
        {/* Content wrapper */}


      </div>
    </>

  )
}

export default Permission

