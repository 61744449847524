import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"

function Subcategory() {
  const navigate = useNavigate("")
  const [addsubcategory, setSubcategory] = useState([])

  useEffect(()=>{getsubcategory()},[])

  const getsubcategory = () =>{
    axios.get("https://dms.maverickconsole.in/api/subcategory")
    .then((res)=>setSubcategory(res.data.data))
  }
  const deletesubcategory = (d) =>{
    axios.delete("https://dms.maverickconsole.in/api/subcategory/"+d)
    .then(()=>getsubcategory())
  }


  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <Sidebar />
      <div className='db'>

        {/* Content wrapper */}
        <div className="content-wrapper pt-5">
          {/* Content */}
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">Master /</span> Sub Category
            </h4>
            <div className="card">
              <div class="row mt-9 card-header">
                <div class="col-md-8">
                  <h4 class="fw-bold py-3 mb-4">Sub Category</h4>
                </div>
                <div class="col-md-3 ">
                  <button onClick={() => { navigate('/addsubcategory'); refreshPage() }} type="submit" className="btn btn-primary">Add Sub Category</button>
                </div>
              </div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S NO.</th>
                      <th>SUB CATEGORY NAME</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {addsubcategory.map((i, n)=>
                    <tr>
                      <td>{n+1}</td>
                      <td>{i.subcategory}</td>
                      <td>
                        <button type="button" className="btn btn-icon btn-outline-danger" onClick={()=>deletesubcategory(i._id)}>
                          <i className="bx bx-trash-alt" />
                        </button>&nbsp;&nbsp;&nbsp;
                        <button  onClick={() => {
                            localStorage.setItem("subcategoryData", JSON.stringify(i))
                            navigate('/editsubcategory')
                          }} type="button" className="btn btn-icon btn-outline-primary">
                          <i className="bx bx-edit" />
                        </button>
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* / Content */}
        </div>
        {/* Content wrapper */}


      </div>
    </>

  )
}

export default Subcategory
