
import { useState, useEffect } from 'react'
import Sidebar from "../Sidebar"
import axios from 'axios'

function Addexpenses() {
    const [category, setCategory] = useState("")
    const [amount, setAmount] = useState("")
    const [name, setName] = useState("")
    const [receipt, setReceipt] = useState("")
    const [date, setDate] = useState("")
    const [project, setProject] = useState("")
    const [customer, setCustomer] = useState("")
    const [invoice, setInvoice] = useState("")
    const [reference, setReference] = useState("")
    const [payment, setPayment] = useState("")
    const [addcategory, setAddcategory] = useState([])

    const data = {
        category: category,
        amount: amount,
        name: name,
        receipt: receipt,
        date: date,
        project: project,
        customer: customer,
        invoice: invoice,
        reference: reference,
        payment: payment,
    }

    function sumitexpense() {
        axios.post("https://dms.maverickconsole.in/api/expense/addExpense", data)
            .then((res) => res.data).then(console.log(data))
    }

    useEffect(() => { getaccount_category() }, [])

    const getaccount_category = () => {
      axios.get("https://dms.maverickconsole.in/api/accountcategory")
        .then((res) => setAddcategory(res.data.data));
    }


    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        <div className="row pt-5">
                            <div className="card mb-4">

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="position" className="form-label">
                                                Account Category
                                                </label>
                                                <select
                                                    value={category}
                                                    onChange={(e) => setCategory(e.target.value)}
                                                    className="form-select"
                                                    aria-label="Default select example"

                                                >
                                                    <option selected="" disabled>Select</option>
                                                    {addcategory.map((i) => <option>{i.account_category}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Amount
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={amount}
                                                        onChange={(e) => setAmount(e.target.value)}
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Amount"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Receipt Number
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={receipt}
                                                        onChange={(e) => setReceipt(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Receipt"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Date
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={date}
                                                        onChange={(e) => setDate(e.target.value)}
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Receipt"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Project
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={project}
                                                        onChange={(e) => setProject(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Project"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Customer
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={customer}
                                                        onChange={(e) => setCustomer(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Customer"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Invoice
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={invoice}
                                                        onChange={(e) => setInvoice(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Invoice"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Reference #
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={reference}
                                                        onChange={(e) => setReference(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Reference"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                    <div className='col-md-4'>
                                    <div className="mb-3">
                                                <label className="form-label" htmlFor="twilio">
                                                    Upload Image & Pdf
                                                </label>
                                                <div className="input-group">
                                                    <input className="form-control" type="file" id="formFileMultiple" multiple />
                                                </div>
                                            </div>
                                            </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="Sex" className="form-label">
                                                    PAYMENT MODE
                                                </label>
                                                <select
                                                    value={payment}
                                                    onChange={(e) => setPayment(e.target.value)}
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="">Select</option>
                                                    <option>Cash</option>
                                                    <option>Online</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={() => sumitexpense()} type="submit" className="btn btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Addexpenses

