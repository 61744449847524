import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"
import axios from "axios";
import { useState, useEffect } from 'react';

function Addjobposition() {
    
    const [permissionData, setPermitionData] = useState([])

    useEffect(() => { modelData() }, [])

    function modelData() {
        axios.get("https://dms.maverickconsole.in/api/user/allmodel")
            .then(response => {

                const newItem = response.data.data.map((i) => {
                    return {
                        name: i,
                        permissions: []

                    }
                })
                setPermitionData(newItem)
            })
    }

    const navigate = useNavigate("")
    const [position, setPosition] = useState("")
  
    function addposition() {
        const data = {
            role:position,
            permission:permissionData
          }
      
        axios.post("https://dms.maverickconsole.in/api/roles/addRole", data)

    }

    function refreshPage() {
        window.location.reload();
      }

    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        <div className="row pt-5">
                            <div className="card mb-4">
                                <p className="card-header">JOB POSITION</p>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Position
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={position}
                                                        onChange={(e) => setPosition(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="position"
                                               
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>




                                <div className="card mb-4">
                                    <div className="card-body">
                                        <p className="form-label" style={{ fontSize: "18px", color: "black" }}>Permission</p>
                                        <div className="card">
                                            <div className="table-responsive text-nowrap">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"><b style={{ fontSize: "15px" }}>Feauture</b></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"><b style={{ fontSize: "15px" }}>Capabilities</b></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {permissionData.map((i) => <tr>
                                                            <th scope="row">{i.name}</th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <input


                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={i.permissions?.includes("viewg")}
                                                                    onClick={() => {
                                                                        if (i.permissions.includes("viewg")) {
                                                                            const newList = permissionData.map((j) => j.name === i.name ? {...j,permissions : j.permissions.filter((i) => i !== "viewg")} : j)
                                                                            setPermitionData(newList)
                                                                        } else {
                                                                            const newList = permissionData.map((j) => j.name === i.name ? {...j,permissions: [...j.permissions, "viewg"]} : j)
                                                                            setPermitionData(newList)
                                                                        }
                                                                    }}

                                                                />&nbsp;&nbsp;&nbsp;
                                                                <label className="form-check-label" htmlFor="P">
                                                                    {" "}
                                                                    View(Global){" "}
                                                                </label>
                                                                <input
                                                                       className="form-check-input"
                                                                       type="checkbox"
                                                                       checked={i.permissions?.includes("views")}
                                                                       onClick={() => {
                                                                           if (i.permissions.includes("views")) {
                                                                               const newList = permissionData.map((j) => j.name === i.name ? {...j,permissions : j.permissions.filter((i) => i !== "views")} : j)
                                                                               setPermitionData(newList)
                                                                           } else {
                                                                               const newList = permissionData.map((j) => j.name === i.name ? {...j,permissions: [...j.permissions, "views"]} : j)
                                                                               setPermitionData(newList)
                                                                           }
                                                                       }}
                                                                />&nbsp;&nbsp;&nbsp;
                                                                <label className="form-check-label" htmlFor="P">
                                                                    {" "}
                                                                    View(Self){" "}
                                                                </label>
                                                                <input
                                                                      className="form-check-input"
                                                                      type="checkbox"
                                                                      checked={i.permissions?.includes("add")}
                                                                      onClick={() => {
                                                                          if (i.permissions.includes("add")) {
                                                                              const newList = permissionData.map((j) => j.name === i.name ? {...j,permissions : j.permissions.filter((i) => i !== "add")} : j)
                                                                              setPermitionData(newList)
                                                                          } else {
                                                                              const newList = permissionData.map((j) => j.name === i.name ? {...j,permissions: [...j.permissions, "add"]} : j)
                                                                              setPermitionData(newList)
                                                                          }
                                                                      }}
                                                                />&nbsp;&nbsp;&nbsp;
                                                                <label className="form-check-label" htmlFor="P">
                                                                    {" "}
                                                                    Add
                                                                </label>
                                                                <input
                                                                         className="form-check-input"
                                                                         type="checkbox"
                                                                         checked={i.permissions?.includes("edit")}
                                                                         onClick={() => {
                                                                             if (i.permissions.includes("edit")) {
                                                                                 const newList = permissionData.map((j) => j.name === i.name ? {...j,permissions : j.permissions.filter((i) => i !== "edit")} : j)
                                                                                 setPermitionData(newList)
                                                                             } else {
                                                                                 const newList = permissionData.map((j) => j.name === i.name ? {...j,permissions: [...j.permissions, "edit"]} : j)
                                                                                 setPermitionData(newList)
                                                                             }
                                                                         }}
                                                                />&nbsp;&nbsp;&nbsp;
                                                                <label className="form-check-label" htmlFor="P">
                                                                    {" "}
                                                                    Edit
                                                                </label>
                                                                <input
                                                                         className="form-check-input"
                                                                         type="checkbox"
                                                                         checked={i.permissions?.includes("delete")}
                                                                         onClick={() => {
                                                                             if (i.permissions.includes("delete")) {
                                                                                 const newList = permissionData.map((j) => j.name === i.name ? {...j,permissions : j.permissions.filter((i) => i !== "delete")} : j)
                                                                                 setPermitionData(newList)
                                                                             } else {
                                                                                 const newList = permissionData.map((j) => j.name === i.name ? {...j,permissions: [...j.permissions, "delete"]} : j)
                                                                                 setPermitionData(newList)
                                                                             }
                                                                         }}
                                                                />&nbsp;&nbsp;&nbsp;
                                                                <label className="form-check-label" htmlFor="P">
                                                                    {" "}
                                                                    Delete
                                                                </label>
                                                            </td>
                                                        </tr>)}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='pt-3'>
                                            <button onClick={()=> addposition()} className="btn btn-primary">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Addjobposition

