import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"
import axios from "axios";
import { useState } from 'react';

function Addtest() {



    const navigate = useNavigate("")
    const [title, setTitle] = useState("")
    const [post, setPost] = useState("")

    function addposition() {
        const data = {
            title: title,
            post: post
        }
        axios.post("https://dms.maverickconsole.in/api/test", data)
            .then(() => navigate("/test")).catch((err) => console.log(err))
    }

    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        {/* <h4 className="fw-bold py-3 mb-4">
                            <span className="text-muted fw-light" />
                        </h4> */}
                        <div className="row pt-5">
                            <div className="card mb-4">
                                <p className="card-header">TEST</p>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Title
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={title}
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="title"

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Post
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={post}
                                                        onChange={(e) => setPost(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="post"

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <button onClick={addposition} type="submit" className="btn btn-primary">
                                        Save
                                    </button> */}

                                </div>




                                <div className="card mb-4">
                                    <div className="card-body">
                                        {/* <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="mb-3">
                                                    <label htmlFor="statu" className="form-label">
                                                        Role
                                                    </label>
                                                    <select
                                                        value={""}
                                                        onChange={(e) => (e.target.value)}
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected="">Select</option>
                                                        <option>Admin</option>
                                                        <option>User</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className='pt-3'>
                                            <button onClick={() => addposition()} className="btn btn-primary">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Addtest

