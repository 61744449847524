import { useState } from 'react'
import Sidebar from "../Sidebar"
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function Editcountry() {
    
    const localData = localStorage.getItem("countrydata")
    const parseData = JSON.parse(localData)
    
    
    const [editcountry, setEditcountry] = useState(parseData.country)
    const [state, setState] = useState(parseData.state)
    const [city, setCity] = useState(parseData.city)
    const navigate = useNavigate("")


    function countrySumbit() {
        const data = {
            country: editcountry,
            state: state,
            city: city
        }
        axios.put("https://dms.maverickconsole.in/api/country/" + parseData._id, data)
            .then(() => navigate("/country"))
    }


    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        <div className="row pt-5">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Add Country</h5>
                                </div>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Country Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={editcountry}
                                                        onChange={(e) => setEditcountry(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="country"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={()=>countrySumbit()} className="btn btn-primary">
                                        Save
                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Editcountry

