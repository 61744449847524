import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"

function Country() {
  const navigate = useNavigate("")
  const [addcountry, setAddcountry] = useState([])

  useEffect(() => { getcountry() }, [])

  const getcountry = () => {
    axios.get("https://dms.maverickconsole.in/api/country")
      .then((res) => setAddcountry(res.data.data));
  }

  const deletecountry = (x) => {
    axios.delete("https://dms.maverickconsole.in/api/country/" + x)
      .then(() => getcountry())
  }

  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <Sidebar />
      <div className='db'>

        {/* Content wrapper */}
        <div className="content-wrapper pt-5">
          {/* Content */}
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">Master /</span> Country
            </h4>

            <div className="card">
              <div class="row mt-9 card-header">
                <div class="col-md-8">
                  <h4 class="fw-bold py-3 mb-4">Country</h4>
                </div>
                <div class="col-md-3 ">
                  <button onClick={() => { navigate('/addcountry'); refreshPage() }} type="submit" className="btn btn-primary">Add Country</button>
                </div>
              </div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S NO.</th>
                      <th>Country Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {addcountry.map((i, n) =>
                      <tr>
                        <td>{n + 1}</td>
                        <td>{i.country}</td>
                        <td>
                          <button type="button" className="btn btn-icon btn-outline-danger" onClick={() => deletecountry(i._id)}>
                            <i className="bx bx-trash-alt" />
                          </button>&nbsp;&nbsp;&nbsp;
                          <button onClick={() => {
                            localStorage.setItem("countrydata", JSON.stringify(i))
                            navigate('/editcountry')

                          }} type="button" className="btn btn-icon btn-outline-primary">
                            <i className="bx bx-edit" />
                          </button>
                        </td>
                      </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* / Content */}
        </div>
        {/* Content wrapper */}


      </div>
    </>

  )
}

export default Country
