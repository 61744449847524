import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export const Signup = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [repeatpassword, setRepeatpassword] = useState("")
    const navigate = useNavigate("")
    function refreshPage() {
        window.location.reload();
    }
    return (
        <>
            <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100 pt-5">
                    <div className="col-md-9 col-lg-6 col-xl-5">
                         <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
          class="img-fluid" alt="Phone image"></img>
                    </div>
                    <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                            <div className="d-flex flex-row align-items-center justify-content-center">
                            <h2 class="form-title">Sign up</h2>
                            </div>
                            {/* Name input */}
                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="Name">
                                    Your Name
                                </label>
                                <input
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                                    type="text"
                                    id="Name"
                                    className="form-control form-control-lg"
                                    placeholder="Enter Your Name"
                                />

                            </div>
                            {/* Email input */}
                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="Email">
                                    Your Email 
                                </label>
                                <input
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                                    type="email"
                                    id="Email"
                                    className="form-control form-control-lg"
                                    placeholder="Enter a valid email address"
                                />

                            </div>
                            {/* Password input */}
                            <label className="form-label" htmlFor="Password">
                                    Password
                                </label>
                            <div className="form-outline mb-3">
                                <input
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                                    type="password"
                                    id="Password"
                                    className="form-control form-control-lg"
                                    placeholder="Enter password"
                                />
                               
                            </div>
                            {/* Repeat Password input */}
                            <label className="form-label" htmlFor="Repeat">
                                    Repeat Password
                                </label>
                            <div className="form-outline mb-3">
                                <input
                                value={repeatpassword}
                                onChange={(e)=>setRepeatpassword(e.target.value)}
                                    type="password"
                                    id="Repeat"
                                    className="form-control form-control-lg"
                                    placeholder="Enter Repeat password"
                                />
                               
                            </div>
                            <div className="text-center text-lg-start mt-4 pt-2">
                                <button onClick={() => { navigate('/'); refreshPage() }}
                                    type="button"
                                    className="btn btn-primary btn-lg"
                                    style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem",cursor:"pointer" }}
                                >
                                    Register
                                </button>
                                <p className="small fw-bold mt-2 pt-1 mb-0">
                                    Have an account?{" "}
                                    <a onClick={() => { navigate('/'); refreshPage() }} className="link-danger" style={{cursor:"pointer"}}>
                                        Login
                                    </a>
                                </p>
                            </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default Signup
