import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Sidebar from "../Sidebar"
import axios from "axios";
import { useEffect } from 'react'


function Profile() {
    useEffect(() => { getRoles(); getPermission() }, [])


    const navigate = useNavigate("")
    const [profileimage, setProfileimage] = useState("")
    const [hrcode, setHrcode] = useState("")
    const [fname, setFname] = useState("")
    const [sex, setSex] = useState("")
    const [birthday, setBirthday] = useState("")
    const [birthplace, setBirthplace] = useState("")
    const [hometown, setHometown] = useState("")
    const [mstatus, setMstatus] = useState("")
    const [marriage, setMarriage] = useState("")
    const [nation, setNation] = useState("")
    const [religion, setReligion] = useState("")
    const [identity, setIdentity] = useState("")
    const [dayidentity, setDayidentity] = useState("")
    const [placeofissue, setPlaceofissue] = useState("")
    const [resident, setResident] = useState("")
    const [caddress, setCaddress] = useState("")
    const [literacy, setLiteracy] = useState("")
    const [status, setStatus] = useState("")
    const [workplace, setWorkplace] = useState("")
    const [bankaccount, setBankaccount] = useState("")
    const [nameofaccount, setNameofaccount] = useState("")
    const [bankofissue, setBankofissue] = useState("")
    const [ptaxcode, setPtaxcode] = useState("")
    const [hourlyrate, setHourlyrate] = useState("")
    const [phone, setPhone] = useState("")
    const [fb, setFb] = useState("")
    const [linkedin, setlinkedin] = useState("")
    const [skype, setSkype] = useState("")
    const [email, setEmail] = useState("")
    const [pemail, setPemail] = useState("")
    const [language, setLanguage] = useState("")
    const [direction, setDirection] = useState("")
    const [emailsignature, setEmailsignature] = useState("")
    const [otherinfo, setOtherinfo] = useState("")
    const [businessdepartment, setBusinessdepartment] = useState("")
    const [sales, setSales] = useState("")
    const [marketing, setMarketing] = useState("")
    const [development, setDevelopment] = useState("")
    const [hr, setHr] = useState("")
    const [twilionumber, setTwilionumber] = useState("")
    const [administrator, setAdministrator] = useState("")
    const [sendwelcomeemail, setSendwelcomeemail] = useState("")
    const [password, setPassword] = useState("")


    const [role, setRole] = useState([])
    const [permission, setPermission] = useState([])
    const [jobposition, setJobposition] = useState([])

    function getRoles() {
        axios.get('https://dms.maverickconsole.in/api/roles/')
            .then(response => {
                const newItem = response.data.data.map((i) => {
                    return {
                        name: i.role,
                        permission: i.permission
                    }
                })
                setRole(newItem)
            })
    }
    const newItem2 = role.filter((i) => i.name === jobposition).map((j) => JSON.stringify(j.permission))
    function getPermission() {
        axios.get('https://dms.maverickconsole.in/api/roles/')
            .then(response => {

                const newI = newItem2.map((i) => {
                    return {
                        permission: i.permissions
                    }
                })
                setPermission(newI)
            })
    }

   
    function handleImg(e) {
        console.log(e.target.files)
        setProfileimage(e.target.files[0])
    }





    const data = {
        profile_image: profileimage,
        hrcode: hrcode,
        fullname: fname,
        sex: sex,
        birthday: birthday,
        birthplace: birthplace,
        hometown: hometown,
        marital_status: mstatus,
        marriage: marriage,
        nation: nation,
        religion: religion,
        identification: identity,
        days_for_identity: dayidentity,
        place_of_issue: placeofissue,
        resident: resident,
        current_address: caddress,
        literacy: literacy,
        status_work: status,
        workplace: workplace,
        account_number: bankaccount,
        name_account: nameofaccount,
        issue_bank: bankofissue,
        Personal_tax_code: ptaxcode,
        hourly_rate: hourlyrate,
        phonenumber: phone,
        facebook: fb,
        linkedin: linkedin,
        skype: skype,
        email: email,
        pemail: pemail,
        default_language: language,
        direction: direction,
        email_signature: emailsignature,
        other_infor: otherinfo,
        member_department: businessdepartment,
        role: jobposition,
        permission: permission,
        password: password

    }
    // alert(data);
    function sumitdata() {
        axios.post("https://dms.maverickconsole.in/api/user/addUser", data)
            .then(response => alert(JSON.stringify(response.data.data)))
    }


    function refreshPage() {
        window.location.reload();
    }

    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y">
                        {/* <h4 className="fw-bold py-3 mb-4">
                            <span className="text-muted fw-light" />
                        </h4> */}
                        <div className="row pt-5">
                            <ul className="nav nav-pills flex-column flex-md-row pt-3">
                                <li className="nav-item">
                                    <a onClick={() => { navigate('/profile'); refreshPage() }} class="btn btn-primary mb-2 nav-link"><i className="bx bx-user me-1" /> Profile</a>
                                </li>&nbsp;&nbsp;&nbsp;
                                <li className="nav-item">
                                    <a onClick={() => { navigate('/permission'); refreshPage() }} class="btn btn-primary mb-2 nav-link"><i className="bx bx-bell me-1" />  Permission</a>
                                </li>
                            </ul>

                            <div className="card mb-4">
                                <p className="card-header"><i class="bx bx-question-mark" style={{ backgroundColor: "black", color: "white", borderRadius: "50%" }}></i>Enable Email Two Factor Authentocation <br />Not Staff Member</p>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="twilio">
                                                    Profile Image
                                                </label>
                                                <div className="input-group">
                                                    <input onChange={handleImg} className="form-control" type="file" id="formFileMultiple" multiple />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="HR">
                                                    HR Code
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        value={hrcode}
                                                        onChange={(e) => setHrcode(e.target.value)}
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Code"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="fullname">
                                                    Full Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={fname}
                                                        onChange={(e) => setFname(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="Sex" className="form-label">
                                                    Sex
                                                </label>
                                                <select
                                                    value={sex}
                                                    onChange={(e) => setSex(e.target.value)}
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="">Select</option>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Birthday
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={birthday}
                                                        onChange={(e) => setBirthday(e.target.value)}
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Date"
                                                        aria-describedby="basic-icon-default-fullname2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Birthplace">
                                                    Birthplace
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={birthplace}
                                                        onChange={(e) => setBirthplace(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Place"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="town">
                                                    Home town
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={hometown}
                                                        onChange={(e) => setHometown(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="home town"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Identification">
                                                    Identification
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        value={identity}
                                                        onChange={(e) => setIdentity(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="fullname">
                                                    Day for identity
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={dayidentity}
                                                        onChange={(e) => setDayidentity(e.target.value)}
                                                        type="date"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="issue">
                                                    place of issue
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={placeofissue}
                                                        onChange={(e) => setPlaceofissue(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Resident">
                                                    Resident
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        value={resident}
                                                        onChange={(e) => setResident(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Address">
                                                    Current Address
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={caddress}
                                                        onChange={(e) => setCaddress(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="literacy">
                                                    literacy
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={literacy}
                                                        onChange={(e) => setLiteracy(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="statu" className="form-label">
                                                    status
                                                </label>
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    value={status}
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <option selected="" disabled>Select</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="position" className="form-label">
                                                    job position
                                                </label>
                                                <select
                                                    value={jobposition}
                                                    onChange={(e) => setJobposition(e.target.value)}
                                                    className="form-select"
                                                    aria-label="Default select example"

                                                >
                                                    <option selected="" disabled>Select</option>
                                                    {role.map((i) => <option>{i.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="workplace" className="form-label">
                                                    workplace
                                                </label>
                                                <select
                                                    value={workplace}
                                                    onChange={(e) => setWorkplace(e.target.value)}
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="" disabled>Select</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="bank">
                                                    bank account#
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        value={bankaccount}
                                                        onChange={(e) => setBankaccount(e.target.value)}
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="number"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="account">
                                                    Name of account
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={nameofaccount}
                                                        onChange={(e) => setNameofaccount(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="bankissue">
                                                    bank of issue
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={bankofissue}
                                                        onChange={(e) => setBankofissue(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Issu"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="tax">
                                                    personal tax code
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        value={ptaxcode}
                                                        onChange={(e) => setPtaxcode(e.target.value)}
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Code"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="hourly">
                                                    hourly rate
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        value={hourlyrate}
                                                        onChange={(e) => setHourlyrate(e.target.value)}
                                                        type="number"
                                                        className="form-control" placeholder='rate'

                                                        aria-label="Dollar amount (with dot and two decimal places)"
                                                    />
                                                    <span className="input-group-text">₹</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="phone">
                                                    phone
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="number"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="facebook">
                                                    facebook
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        value={fb}
                                                        onChange={(e) => setFb(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="facebook"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="linkedin">
                                                    linkedin
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={linkedin}
                                                        onChange={(e) => setlinkedin(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="linkedin"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="skype">
                                                    skype
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={skype}
                                                        onChange={(e) => setSkype(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="skype"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="emails">
                                                    email
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="email"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="emails">
                                                    Personal email
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        value={pemail}
                                                        onChange={(e) => setPemail(e.target.value)}
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="email"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="Sex" className="form-label">
                                                    direction
                                                </label>
                                                <select
                                                    value={direction}
                                                    onChange={(e) => setDirection(e.target.value)}
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected="">System Default</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="Material" className="form-label">
                                                    Material Status
                                                </label>
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    onChange={(e) => setMstatus(e.target.value)}
                                                    value={mstatus}
                                                >
                                                    <option selected="" disabled>Select</option>
                                                    <option>Married</option>
                                                    <option>Unmarried</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Religion">
                                                    Marriage Anniversery
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={marriage}
                                                        onChange={(e) => setMarriage(e.target.value)}
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Nation">
                                                    Nation
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={nation}
                                                        onChange={(e) => setNation(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="Religion">
                                                    Religion
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={religion}
                                                        onChange={(e) => setReligion(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label htmlFor="language" className="form-label">
                                                    default language
                                                </label>
                                                <select
                                                    value={language}
                                                    onChange={(e) => setLanguage(e.target.value)}
                                                    className="form-select"
                                                    aria-label="Default select example">
                                                    <option selected="">System Default</option>
                                                    <option>Hindi</option>
                                                    <option>English</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="emails">
                                                    <i class="bx bx-question-mark" style={{ backgroundColor: "black", color: "white", borderRadius: "50%" }}></i> email signature
                                                </label>
                                                <textarea onChange={(e) => setEmailsignature} value={emailsignature} class="form-control" rows="3"></textarea>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="emails">
                                                    other info
                                                </label>
                                                <textarea onChange={(e) => setOtherinfo} value={otherinfo} class="form-control" rows="3"></textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="twilio">
                                                    Member Departments
                                                </label>
                                                <div className="form-check mt-3">
                                                    <input
                                                        value={businessdepartment}
                                                        onChange={(e) => setBusinessdepartment(e.target.value)}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                                        {" "}
                                                        Business Department{" "}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        value={sales}
                                                        onChange={(e) => setSales(e.target.value)}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        defaultChecked=""
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck2">
                                                        {" "}
                                                        Sales{" "}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        onChange={marketing}
                                                        value={(e) => setMarketing(e.target.value)}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        defaultChecked=""
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck3">
                                                        {" "}
                                                        Marketing{" "}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        onChange={(e) => setDevelopment(e.target.value)}
                                                        value={development}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        disabled=""
                                                    />
                                                    <label className="form-check-label" htmlFor="disabledCheck1">
                                                        {" "}
                                                        Development{" "}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        value={hr}
                                                        onChange={(e) => setHr(e.target.value)}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        disabled=""
                                                        defaultChecked=""
                                                    />
                                                    <label className="form-check-label" htmlFor="disabledCheck2">
                                                        {" "}
                                                        HR{" "}
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="twilio">
                                                    twilio phone number
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    {/* <span id="HR" className="input-group-text">
                                                        <i className="bx bx-user" />
                                                    </span> */}
                                                    <input
                                                        value={twilionumber}
                                                        onChange={(e) => setTwilionumber(e.target.value)}
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="name"
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="mb-3">
                                                <div className="form-check mt-3">
                                                    <input
                                                        value={administrator}
                                                        onChange={(e) => setAdministrator(e.target.value)}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                                        {" "}
                                                        Administrator{" "}
                                                    </label>
                                                </div>
                                                <div className="form-check mt-3">
                                                    <input
                                                        value={sendwelcomeemail}
                                                        onChange={(e) => setSendwelcomeemail(e.target.value)}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                                        {" "}
                                                        Send welcome email {" "}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="twilio">
                                                    Password
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        aria-label="Dollar amount (with dot and two decimal places)"
                                                    />
                                                    <span className="input-group-text"><i class="bx bx-hide"></i></span>
                                                    <span className="input-group-text"><i class="bx bx-refresh"></i></span>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <button type="submit" onClick={sumitdata} className="btn btn-primary">
                                        Save
                                    </button>

                                </div>
                            </div>

                            {/* {JSON.stringify(permission)} */}
                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Profile

