

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../Sidebar"

function Editcategory() {
    const localdata = localStorage.getItem("categoryData")
    const parseData = JSON.parse(localdata)
    const navigate = useNavigate()
    const [category, setCategory] = useState(parseData.category)

function editcategory(){
    const data = {
        category: category,
    }

    axios.put("https://dms.maverickconsole.in/api/category/" + parseData._id, data)
        .then(()=> navigate('/category'))
    
}


    return (
        <>
            <Sidebar />
            <div className='db'>

                {/* Content wrapper */}
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="container-xxl flex-grow-1 container-p-y pt-5">
                        <div className="row pt-5">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Add Category</h5>
                                </div>

                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Category Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        value={category}
                                                        onChange={(e) => setCategory(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Category"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="BIRTHDAY">
                                                    Status
                                                </label>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" defaultChecked />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <button onClick={()=>editcategory()} type="submit" className="btn btn-primary">
                                        Save
                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/* / Content */}
                </div>
                {/* Content wrapper */}


            </div>
        </>

    )
}

export default Editcategory

